<template>
  <header class="header_area">
    <div class="breadcrumbs">
      <a
        v-if="showbackbtn && $store.getters.showbackbtn"
        class="d-flex justify-content-center align-items-center"
        @click="goBack()"
      >
        <div class="icon-back" />
        <div class="icon-back-text">回上頁</div>
      </a>
    </div>

    <div v-if="logo" class="logo-wrap">
      <router-link to="/">
        <img :src="logo" class="logo-img" alt="logo">
      </router-link>
    </div>
  </header>
</template>

<script>
import { getShopInfo } from '@/api/shop'
export default {
  props: {
    backPath: {
      type: String,
      default: () => null
    },
    showbackbtn: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      logo: null
      // logo: require('@/assets/images/logo-3trip.svg')
    }
  },
  created() {
    if (
      this.$store.getters.shopInfo &&
      this.$store.getters.shopInfo.logo2 !== undefined
    ) {
      this.logo = this.$store.getters.shopInfo.logo2
    } else {
      getShopInfo().then(response => {
        this.$store.commit('SET_INFO', response.data.data)
        this.logo = response.data.data.logo2
      })
    }
  },
  methods: {
    goBack() {
      if (!this.backPath) {
        if (window.history.length < 1) {
          this.$router.push({ path: '/index' }).catch(() => {})
        } else {
          this.$router.go(-1)
        }
      } else {
        if (this.backPath === 'opapp') {
          window.OPAPPJsInterface.call('OPCloseWebview', null)
        } else {
          this.$router.push({ path: this.backPath }).catch(() => {})
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'assets/scss/common.scss';
header {
  padding: 2px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  margin: 0 auto;
  padding: 12px 0;
  background: $white-color;
  box-shadow: 0px 1px 30px 0px rgba($main-color, $alpha: 0.3);
  z-index: 100;
  .breadcrumbs {
    position: absolute;
    left: 0;
    padding: 0 1rem;
    .icon-back {
      width: 20px;
      height: 20px;
      padding: 0;
      vertical-align: middle;
      background-color: $main-color;
      -webkit-mask: url(~@/assets/images/icon-back.svg) no-repeat left top;
      mask: url(~@/assets/images/icon-back.svg) no-repeat left top;
      background-size: cover;
    }
    .icon-back-text {
      font-size: 14px;
    }
  }

  .brand-icon {
    height: 20px;
    padding: 0px;
    max-width: 170px;
    margin: 0 auto;
    background: url(~@/assets/images/logo-3trip.svg) no-repeat center center;
  }

  .logo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    padding: 0 80px;
  }

  .logo-img {
    height: 20px;
    max-width: 100%;
    object-fit: contain;
    object-position: center;
  }
}
@media screen and (min-width: 640px) {
  header {
    max-width: 640px;
    .brand-icon {
      max-width: 100%;
    }
  }
}

@media screen and (min-width: 768px) {
  header {
    max-width: 768px;
  }
}
</style>
