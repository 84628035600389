<template>
  <div class="breadcrumbs">
    <a @click="goBack()">
      <div class="icon-back" />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    backWord: {
      type: String,
      default: () => '返回'
    },
    backPath: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
    }
  },
  methods: {
    goBack() {
      if (!this.backPath) {
        if (window.history.length < 1) {
          this.$router.push({ path: '/index' }).catch(() => {
          })
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$router.push({ path: this.backPath }).catch(() => {
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "assets/scss/common.scss";
  .breadcrumbs {
    padding: .8rem 15px;
    a{
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $main-color;
    }
    a:hover{
      text-decoration: none;
    }
    .icon-back{
      width: 25px;
      height: 23px;
      padding: 0px 0 0 6px;
      vertical-align: middle;
      background-color: $main-color;
      -webkit-mask: url(~@/assets/images/icon-back.svg) no-repeat left top;
      mask: url(~@/assets/images/icon-back.svg) no-repeat left top;
    }
    p{
      margin: 0;
      padding: 0 0 0 5px;
      font-size: 1rem;
      line-height: .9rem;
    }
  }
</style>
